import { render, staticRenderFns } from "./LandingINTMesaRegalos.vue?vue&type=template&id=302f9ef8&scoped=true&"
import script from "./LandingINTMesaRegalos.vue?vue&type=script&lang=js&"
export * from "./LandingINTMesaRegalos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "302f9ef8",
  null
  
)

export default component.exports