<template>
  <section class="ln-como-cols" :class="className">
    <div class="bb-container">
      <div class="ln-head">
        <div class="ln-head__titulo">
          {{ title }}
        </div>
        <div class="ln-head__subtitulo">
          {{ subtitle }}
        </div>
      </div>
      <div class="ln-como-cols__items">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import bbButton from "../../../components/buttons/bbButton.vue";
import ButtonTop from "./ButtonTop.vue";

export default {
  name: "LandingComoCols",
  components: {
    bbButton,
    ButtonTop,
  },
  props: {
    title: String,
    subtitle: String,
    className: String,
  },
  data() {
    return {};
  },
};
</script>
