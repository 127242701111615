<template>
  <div class="wrapper-landing">
    <LandingHero
      title="La mesa de regalos para tu bebé sin límites"
      subtitle="Elige exactamente qué deseas para tu bebé y compártelo con los que más quieres"
      origin="mesaregalos"
      buttonText="Empieza tu mesa ahora"
    >
      <template v-slot:cover>
        <source
          media="(max-width: 991px)"
          class=""
          srcset="../../assets/img/landings/cover-mujer-joven-embarazada-movil-resp.jpg"
          alt=""
          loading="lazy"
        />
        <img
          class="ln-hero__inset"
          src="../../assets/img/landings/cover-mujer-joven-embarazada-movil.jpg"
          alt=""
          loading="lazy"
        />
      </template>
    </LandingHero>
    <main class="bg-cercles">
      <LandingAppRatings />

      <section class="bb-container">
        <LandingVideo v-show="showVideo" title="Mira como es una mesa de regalos multitienda">
          <template slot="videoDesktop">
            <video
              ref="videoPlayer"
              controls
              loop
              poster="../../assets/video/video-explicativo-cover.jpg"
            >
              <source src="../../assets/video/video-presentacion-latam.mp4" />
            </video>
          </template>
          <template slot="video">
            <video
              ref="videoPlayer"
              controls
              loop
              poster="../../assets/video/video-explicativo-cover-vertical.jpg"
            >
              <source src="../../assets/video/video-presentacion-latam.mp4" />
            </video>
          </template>
        </LandingVideo>
        <LandingFeaturesSimple
          title="Necesitas una mesa de regalos de HelloBB si:"
          :use-carousel="false"
        >
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-embarazada.svg" alt="" />
            </template>
            <template slot="title">
              Quieres poder pedir cosas de varias tiendas.
            </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-q.svg" alt="" />
            </template>
            <template slot="title">
              Eres una mamá/papá novat@ que no sabe qué necesita.
            </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-ciguena.svg" alt="" />
            </template>
            <template slot="title">
              Vas a celebrar un Baby Shower o un bautizo.
            </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-hand-heart.svg" alt="" />
            </template>
            <template slot="title">
              Buscas una forma simpática y sencilla de compartir tus deseos.
            </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-clipboard.svg" alt="" />
            </template>
            <template slot="title">
              Te gusta organizarte y no te quieres olvidar de nada.
            </template>
          </LandingFeaturesSimpleItem>
        </LandingFeaturesSimple>
      </section>
    </main>

    <LandingFeaturesCover title="Una mesa de regalos cargada de cosas buenas">
      <LandingFeaturesCoverItem>
        <template slot="cover">
          <img src="../../assets/img/covers/cover-elige-int.svg" alt="" />
        </template>
        <template slot="titulo">
          Elige cualquier modelo, de cualquier marca, de cualquier tienda.
        </template>

        <template slot="descripcion">
          <p>
            Copia cualquier enlace y guárdalo en tu mesa de regalos. Libertad total para
            escoger justo lo que quieres.
          </p>
        </template>
      </LandingFeaturesCoverItem>
      <LandingFeaturesCoverItem>
        <template slot="cover">
          <img src="../../assets/img/covers/cover-regalos-facil.svg" alt="" />
        </template>
        <template slot="titulo"> Bonita y fácil de usar para tod@s</template>

        <template slot="descripcion">
          <p>
            Fácil para ti y también para personas menos habituadas a la tecnología.
          </p>
        </template>
      </LandingFeaturesCoverItem>
      <LandingFeaturesCoverItem>
        <template slot="cover">
          <img src="../../assets/img/covers/cover-regalos-help.svg" alt="" />
        </template>
        <template slot="titulo"> Te ayudamos a saber qué necesitaréis </template>

        <template slot="descripcion">
          <p>
            Si estás algo perdid@, te ayudaremos a inspirarte y elegir lo que de verdad
            necesitará tu familia.
          </p>
        </template>
      </LandingFeaturesCoverItem>
      <LandingFeaturesCoverItem>
        <template slot="cover">
          <img src="../../assets/img/covers/cover-regalos-party.svg" alt="" />
        </template>
        <template slot="titulo"> Perfecta para compartir tus deseos </template>

        <template slot="descripcion">
          <p>
            ¿Baby Shower? ¿Bautizo? ¿Te preguntan qué necesitará tu bebé? Transmite tus
            deseos de forma cálida y elegante.
          </p>
        </template>
      </LandingFeaturesCoverItem>
    </LandingFeaturesCover>

    <main class="bg-cercles">
      <section class="bb-container">
        <LandingReviews />
        <!-- <LandingHablan /> -->
      </section>
    </main>

    <LandingComoCols title="¿Cómo funciona?" subtitle="Tu mesa de regalos digital.">
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-click.svg" alt="" />
        </template>
        <template slot="titulo">Elige qué quieres</template>
        <template slot="descripcion">
          <p>
            Escoge el objeto que quieras de cualquier tienda online, copia el enlace en
            HelloBB y guárdalo.
          </p>
          <p>
            ¿Necesitas ideas o no sabes bien qué necesitas? No hay problema, te
            recomendaremos las mejores cosas.
          </p>
        </template>
      </LandingComoColsItem>
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-share.svg" alt="" />
        </template>
        <template slot="titulo">Comparte tus deseos</template>
        <template slot="descripcion">
          <p>Cuando quieras, comparte tu mesa de regalos con tus familiares y amigos.</p>
          <p>
            Enviales un link para que accedan desde cualquier sitio y sin registrarse a
            una bonita mesa de regalos personalizada por ti.
          </p>
        </template>
      </LandingComoColsItem>
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-gift.svg" alt="" />
        </template>
        <template slot="titulo">Consigue los regalos</template>
        <template slot="descripcion">
          <p>
            Tus allegados podrán escoger el regalo de tu mesa que más les guste y
            comprarlo. Te lo podrán mandar a ti directamente o a ellos para dároslo en una
            celebración.
          </p>
          <p>
            Para que nadie más lo compre, podrán marcar en la mesa de regalos que ya está
            regalado
          </p>
        </template>
      </LandingComoColsItem>
    </LandingComoCols>

    <LandingFooter />
  </div>
</template>

<script>
import LandingFeaturesSlider from "./components/LandingFeaturesSlider.vue";

import LandingFeaturesSimple from "./components/LandingFeaturesSimple.vue";
import LandingFeaturesSimpleItem from "./components/LandingFeaturesSimpleItem.vue";

import LandingHero from "./components/LandingHero.vue";
import LandingAppRatings from "./components/LandingAppRatings.vue";
import LandingFeaturesCover from "./components/LandingFeaturesCover";
import LandingFeaturesCoverItem from "./components/LandingFeaturesCoverItem";
import LandingReviews from "./components/LandingReviews.vue";
import LandingHablan from "./components/LandingHablan.vue";
import LandingFooter from "./components/LandingFooter.vue";

import LandingComoCards from "./components/LandingComoCards.vue";
import LandingComoCardsItem from "./components/LandingComoCardsItem.vue";
import LandingComoColsItem from "./components/LandingComoColsItem.vue";
import LandingComoCols from "./components/LandingComoCols.vue";

import LandingVideo from "./components/LandingVideo.vue";

// import bbButton from "../../components/buttons/bbButton.vue";

export default {
  name: "LandingINTMesaRegalos",
  metaInfo() {
    return {
      title: "Mesa de regalos digital | HelloBB",
      meta: [
        { name: "description", content: "Haz la mesa de regalos de tu bebé con HelloBB" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    LandingComoCols,
    LandingComoColsItem,
    LandingAppRatings,
    LandingHablan,
    LandingReviews,
    LandingFooter,
    LandingHero,
    LandingFeaturesCover,
    LandingFeaturesCoverItem,
    LandingFeaturesSlider,
    LandingFeaturesSimple,
    LandingFeaturesSimpleItem,
    LandingComoCards,
    LandingComoCardsItem,
    LandingVideo,
  },
  computed: {
    showVideo() {
      return this.$route.meta.video != false
    },
  },
};
</script>

<style lang="scss" scoped></style>
