<template>
  <div class="ln-como__item">
    <div class="ln-como__card">
      <div class="ln-como__card-title">
        <slot name="icono"></slot>
        <slot name="titulo"></slot>
      </div>
      <div class="ln-como__card-conent">
        <slot name="descripcion"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingComoCardsItem",
};
</script>

<style lang="scss" scoped></style>
