<template>
  <div class="ln-como-cols__item">
    <div class="ln-como-cols__card">
      <div class="ln-como-cols__card-ico">
        <slot name="icono"></slot>
      </div>
      <div class="ln-como-cols__card-title">
        <slot name="titulo"></slot>
      </div>
      <div class="ln-como-cols__card-conent">
        <slot name="descripcion"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingComoColsItem",
};
</script>

<style lang="scss" scoped></style>
