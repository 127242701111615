<template>
  <div class="ln-features">
    <div class="ln-head">
      <div class="ln-head__titulo">
        {{ titulo }}
      </div>
    </div>
    <div class="ln-features__slick">
      <VueSlickCarousel id="vs3" v-bind="sliderItems">
        <slot></slot>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "LandingFeaturesSlider",
  components: {
    VueSlickCarousel,
  },
  props: {
    titulo: String,
  },
  data() {
    return {
      sliderItems: {
        slidesToShow: 4,
        arrows: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              draggable: true,
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              draggable: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
};
</script>
