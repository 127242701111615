<template>
  <bbButton
    :label="label"
    @click="scrollToClass('.scrollto')"
    class="button--accent button--wide-4"
  ></bbButton>
</template>

<script>
import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "ButtonTop",
  components: {
    bbButton,
  },
  props: {
    label: String,
  },
  methods: {
    scrollToClass(className) {
      let element = this.$el.querySelector(className);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
