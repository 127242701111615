<template>
  <section class="ln-como">
    <div class="bb-container" :class="horizontal ? '' : 'bb-container--lista'">
      <div class="ln-head">
        <div class="ln-head__titulo">
          {{ title }}
        </div>
        <div class="ln-head__subtitulo">
          {{ subtitle }}
        </div>
      </div>
      <div class="ln-como__items" :class="horizontal ? 'ln-como__items--horizontal' : ''">
        <VueSlickCarousel id="vscomocards" v-bind="comoSection">
          <slot></slot>
        </VueSlickCarousel>
      </div>
      <div class="back-to-top">
        <bbButton
          label="Empezar ahora"
          @click="scrollToTop()"
          class="button--accent button--wide-4"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import bbButton from "../../../components/buttons/bbButton.vue";
import ButtonTop from "./ButtonTop.vue";

export default {
  name: "LandingComoCards",
  components: {
    VueSlickCarousel,
    bbButton,
    ButtonTop,
  },
  props: {
    title: String,
    subtitle: String,
    horizontal: Boolean,
  },
  data() {
    return {
      comoSection: {
        slidesToShow: 1,
        arrows: false,
        autoplay: false,
        dots: true,
        responsive: [
          // {
          //   breakpoint: 9999,
          //   settings: "unslick",
          // },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              unslick: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    scrollToTop() {
      //scroll to element with class = scrollto
      const el = document.querySelector(".scrollto");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
