<template>
  <section class="ln-video">
    <div class="ln-video__title">
      {{ title }}
    </div>
    <div class="ln-video__content">
      <div class="ln-video__video">
        <template
          v-if="this.$mq === 'm768' || this.$mq === 'm992' || this.$mq === 'm1200'"
        >
          <slot name="videoDesktop">
            <video
              ref="videoPlayer"
              controls
              loop
              poster="../../../assets/video/video-explicativo-cover.jpg"
            >
              <source src="../../../assets/video/video-explicativo.mp4" />
            </video>
          </slot>
        </template>
        <template v-else>
          <slot name="video">
            <video
              ref="videoPlayer"
              controls
              loop
              poster="../../../assets/video/video-explicativo-cover-vertical.jpg"
            >
              <source src="../../../assets/video/video-explicativo.mp4" />
            </video>
          </slot>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    reproducir() {
      // Accede al elemento video utilizando $refs
      const video = this.$refs.videoPlayer;

      // Verifica si el video está pausado, si es así, lo reproduce
      if (video.paused) {
        video.play();
      }
    },
  },
  props: {
    videoDesktop: {
      type: String,
      default: "video-explicactivo",
    },
    video: {
      type: String,
      default: "video-explicactivo",
    },
    title: {
      type: String,
    },
  },
};
</script>
