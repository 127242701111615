<template>
  <section class="ln-features-cover">
    <div class="bb-container">
      <div class="ln-head">
        <div class="ln-head__titulo">
          {{ title }}
        </div>
      </div>

      <section class="ln-features-cover__items">
        <!-- <VueSlickCarousel id="vs1" v-bind="featCover"> </VueSlickCarousel> -->
        <slot></slot>
      </section>
    </div>
  </section>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "LandingFeaturesCover",
  components: {
    // VueSlickCarousel,
  },
  props: {
    title: String,
  },
  // data() {
  //   return {
  //     featCover: {
  //       slidesToShow: 4,
  //       arrows: false,
  //       responsive: [
  //         {
  //           breakpoint: 9999,
  //           settings: "unslick",
  //         },
  //         {
  //           breakpoint: 991,
  //           settings: {
  //             slidesToShow: 2,
  //             draggable: true,
  //             dots: true,
  //             adaptiveHeight: true,
  //           },
  //         },
  //         {
  //           breakpoint: 767,
  //           settings: {
  //             slidesToShow: 1,
  //             draggable: true,
  //             dots: true,
  //             adaptiveHeight: true,
  //           },
  //         },
  //       ],
  //     },
  //   };
  // },
};
</script>
