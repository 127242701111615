<template>
  <div class="ln-features-cover__item-wrapper">
    <div class="ln-features-cover__item">
      <div class="ln-features-cover__cover">
        <slot name="cover"></slot>
      </div>
      <div class="ln-features-cover__col">
        <div class="ln-features-cover__title">
          <slot name="titulo"></slot>
        </div>
        <div class="ln-features-cover__description">
          <slot name="descripcion"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingFeaturesCover",
};
</script>

<style lang="scss" scoped></style>
